import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, Typography, SvgIcon } from '@material-ui/core';
import { withClientConfigs } from '../../../services/ClientConfigsContextProvider';
import { EventList } from '../utils/EventList';
import { hooks } from 'botframework-webchat-component';
import { colorPalette } from 'ri-components';
import useAccessibility from '../hooks/useAccessibility';
import ReplayIcon from '@material-ui/icons/Replay';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditModalSelector from './edit-modal/EditModalSelector';
import { ClaimStatusContext } from '../../../services/ClaimStatusContextProvider';
import { DefaultHiddenIconsStatuses } from '../utils/DefaultHiddenIconsStatuses';
import InformationalModal from '../suggested-actions/InformationalModal';
import { getSvgDataFromUrl } from '../utils/svg-utils';
import clsx from 'clsx';
import { isMasked } from '../utils/activity-utils';

const { black } = colorPalette;

const useStyles = ({ from, fromId, editableKey }) =>
  makeStyles((theme) => ({
    informationMessage: {
      marginLeft: '3px',
      marginTop: '-3px',
      padding: '0px',
      color: theme.message.hyperlinkColor,
    },
    messageStyle: {
      border: '2px solid rgba(0, 0, 0, 0.0)',
      marginTop: editableKey ? '20px' : 0,
      position: 'relative',
      display: 'inline-block',
      maxWidth: '500px',
      minWidth: from === 'user' || fromId === 'user' ? '60px' : 'auto',
      borderRadius: theme.spacing(2.75),
      textAlign: 'start',
      padding: '12px',
      backgroundColor:
        from === 'user' || fromId === 'user'
          ? theme.message.user.backgroundColor
          : theme.message.bot.backgroundColor,
      [theme.breakpoints.between(0, 350)]: {
        maxWidth: '90%',
      },
      [theme.breakpoints.between(350, 420)]: {
        maxWidth: '88%',
      },
      [theme.breakpoints.up(420)]: {
        maxWidth: '70%',
      },
      '& .md-paragraph': {
        margin: 0,
      },
      '& .md-icon': {
        width: 20,
        height: 20,
        color: theme.message.hyperlinkColor,
      },
      '& .md-link': {
        color: theme.message.hyperlinkColor,
      },
      '& > * > * > ul': {
        padding: 'revert',
      },
    },
    editIcon: {
      position: 'absolute',
      width: '32px',
      height: '32px',
      padding: '4px',
      backgroundColor: 'white',
      borderRadius: '20%',
      border: 'solid 2px',
      borderColor: theme.palette.primary.main,
      color: black,
      '&:hover': {
        width: '35px',
        height: '35px',
        border: 'solid 3px',
        borderColor: black,
        transition: '200ms',
      },
      ':focus &': {
        width: '35px',
        height: '35px',
        border: 'solid 3px',
        borderColor: black,
        transition: '200ms',
      },
    },
    editButtonIcon: {
      position: 'absolute',
      right: '3px',
      top: '-16px',
    },
    messageTypography: from === 'user' || fromId === 'user' ? theme.message.user : theme.message.bot,
  }));

const { useSendMessageBack } = hooks;

const MessageBubble = ({
  from,
  fromId,
  children,
  isText,
  editableKey,
  arrayKey,
  arrayIndexKey,
  validationRegex,
  validationMessage,
  editType,
  timeKey,
  dateKey,
  editLabels,
  isContinue,
  properties,
  maskType,
  isUndoIconVisible,
  utcDateTimeProp,
  minLength,
  minLengthValidationMsg,
  maxLength,
  maxLengthValidationMsg,
  keyboardType,
  hiddenIconsStatuses,
  activityId,
  clientConfigs,
}) => {
  const typographyVariant = from === 'user' || fromId === 'user' ? 'body2' : 'body1';
  const classes = useStyles({ from, fromId, editableKey })();
  const [openModalOverlay, setOpenModalOverlay] = useState(false);
  const [openInformationalOverlay, setOpenInformationalOverlay] = useState(false);
  const [svgIconPath, setSvgIconPath] = useState('');

  const sendMessageBack = useSendMessageBack();

  const [isIconVisible, setIsIconVisible] = useState(true);
  const { status: claimStatus } = useContext(ClaimStatusContext);

  function sendUndoMessage() {
    sendMessageBack('', EventList.UndoLastQuestion, '');
  }

  function validateMessage(value) {
    let errorMessage;

    let validMessageRegex = validationRegex?.includes('\\p{L}')
      ? new RegExp(validationRegex, 'u')
      : new RegExp(validationRegex);

    if (value && !validMessageRegex.test(value)) {
      errorMessage = validationMessage;
    }
    return errorMessage;
  }

  useEffect(() => {
    if (
      (DefaultHiddenIconsStatuses.includes(claimStatus.value) ||
        hiddenIconsStatuses?.includes(claimStatus.value)) &&
      activityId < claimStatus.index
    ) {
      setIsIconVisible(false);
    }
  }, [hiddenIconsStatuses, activityId, claimStatus]);

  useEffect(() => {
    // accessibility
    document.querySelector(`[class*="makeStyles-errorMsg"]`)?.setAttribute('aria-live', 'polite');
  });

  const { editAnswer } = useAccessibility();

  useEffect(() => {
    if (clientConfigs?.ClientAssetsUrl && properties?.informationalPopupIconName) {
      getSvgDataFromUrl(
        `${clientConfigs?.ClientAssetsUrl}/JourneyIcons/${properties?.informationalPopupIconName}`,
      )
        .then((response) => setSvgIconPath(response))
        .catch(() => setSvgIconPath(null));
    }
  }, [clientConfigs, properties]);

  if (properties?.informationalPopupIconName && svgIconPath === '') return <></>;

  return (
    <>
      <div className={classes.messageStyle} data-testid='message-bubble'>
        <Typography
          component={isText ? 'p' : 'div'}
          variant={typographyVariant}
          data-testid='text-plain'
          className={classes.messageTypography}
        >
          {children}
          {(!isContinue || !isMasked(children)) &&
            editableKey &&
            isIconVisible &&
            !validateMessage(children) && (
              //Edit button
              <IconButton
                color='primary'
                disableRipple
                className={classes.editButtonIcon}
                onClick={() => {
                  setOpenModalOverlay(true);
                }}
                aria-label={editAnswer}
              >
                <SvgIcon className={classes.editIcon + ' edit-icon'} viewBox='0 0 500 500'>
                  <path d='M373.1 24.97C401.2-3.147 446.8-3.147 474.9 24.97L487 37.09C515.1 65.21 515.1 110.8 487 138.9L289.8 336.2C281.1 344.8 270.4 351.1 258.6 354.5L158.6 383.1C150.2 385.5 141.2 383.1 135 376.1C128.9 370.8 126.5 361.8 128.9 353.4L157.5 253.4C160.9 241.6 167.2 230.9 175.8 222.2L373.1 24.97zM440.1 58.91C431.6 49.54 416.4 49.54 407 58.91L377.9 88L424 134.1L453.1 104.1C462.5 95.6 462.5 80.4 453.1 71.03L440.1 58.91zM203.7 266.6L186.9 325.1L245.4 308.3C249.4 307.2 252.9 305.1 255.8 302.2L390.1 168L344 121.9L209.8 256.2C206.9 259.1 204.8 262.6 203.7 266.6zM200 64C213.3 64 224 74.75 224 88C224 101.3 213.3 112 200 112H88C65.91 112 48 129.9 48 152V424C48 446.1 65.91 464 88 464H360C382.1 464 400 446.1 400 424V312C400 298.7 410.7 288 424 288C437.3 288 448 298.7 448 312V424C448 472.6 408.6 512 360 512H88C39.4 512 0 472.6 0 424V152C0 103.4 39.4 64 88 64H200z' />
                </SvgIcon>
              </IconButton>
            )}
          {properties?.hasInformationalPopUp === true && (
            <IconButton
              color='primary'
              disableRipple
              className={classes.informationMessage}
              onClick={() => {
                setOpenInformationalOverlay(true);
              }}
            >
              {svgIconPath !== null ? (
                <svg className={clsx('informational-popup-icon')} viewBox='0 0 512 512'>
                  <path d={svgIconPath} />
                </svg>
              ) : (
                <InfoOutlinedIcon className={clsx('informational-popup-icon')} />
              )}
            </IconButton>
          )}
          {isIconVisible && isUndoIconVisible && !validateMessage(children) && (
            //Undo button
            <IconButton
              color='primary'
              disableRipple
              className={classes.editButtonIcon}
              onClick={() => sendUndoMessage(isUndoIconVisible)}
            >
              <ReplayIcon
                className={classes.editIcon + ' undo-icon'}
                fontSize={'small'}
                htmlColor={'#000000'}
              />
            </IconButton>
          )}
        </Typography>
      </div>
      <div>
        {openModalOverlay && (
          <EditModalSelector
            editLabels={editLabels}
            editType={editType}
            editableKey={editableKey}
            arrayIndexKey={arrayIndexKey}
            arrayKey={arrayKey}
            timeKey={timeKey}
            dateKey={dateKey}
            from={from}
            fromId={fromId}
            openModalOverlay={openModalOverlay}
            setOpenModalOverlay={setOpenModalOverlay}
            properties={properties}
            validationRegex={validationRegex}
            validationMessage={validationMessage}
            utcDateTimeProp={utcDateTimeProp}
            minLength={minLength}
            minLengthValidationMsg={minLengthValidationMsg}
            maxLength={maxLength}
            maxLengthValidationMsg={maxLengthValidationMsg}
            keyboardType={keyboardType}
          >
            {children}
          </EditModalSelector>
        )}
      </div>
      <div>
        {openInformationalOverlay && (
          <InformationalModal
            id={activityId}
            openInformationalOverlay={openInformationalOverlay}
            setOpenInformationalOverlay={setOpenInformationalOverlay}
            {...properties}
          />
        )}
      </div>
    </>
  );
};

export default withClientConfigs(MessageBubble);

MessageBubble.defaultProps = {
  from: 'bot',
  clientConfigs: {
    HyperlinkColour: '',
    FontColour: '"#FFFFFF"',
  },
  isText: true,
};

MessageBubble.propTypes = {
  from: PropTypes.string,
  fromId: PropTypes.string,
  children: PropTypes.node,
  clientConfigs: PropTypes.any,
  isText: PropTypes.bool,
  editableKey: PropTypes.string,
  arrayKey: PropTypes.string,
  arrayIndexKey: PropTypes.number,
  validationRegex: PropTypes.string,
  validationMessage: PropTypes.string,
  editType: PropTypes.string,
  properties: PropTypes.any,
  editLabels: PropTypes.object,
  timeKey: PropTypes.string,
  dateKey: PropTypes.string,
  isContinue: PropTypes.bool,
  maskType: PropTypes.string,
  isUndoIconVisible: PropTypes.bool,
  utcDateTimeProp: PropTypes.string,
  minLength: PropTypes.string,
  minLengthValidationMsg: PropTypes.string,
  maxLength: PropTypes.string,
  maxLengthValidationMsg: PropTypes.string,
  keyboardType: PropTypes.string,
  hiddenIconsStatuses: PropTypes.any,
  activityId: PropTypes.any,
};
