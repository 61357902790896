import React, { useState } from 'react';
import { ModalOverlay, ModalOverlayBody, ModalOverlayTitle } from 'ri-components';
import PropTypes from 'prop-types';
import ClaimButton from '../../../../components/ClaimButton';
import { makeStyles } from '@material-ui/core/styles';
import { PhoneInput } from 'react-international-phone';
import 'react-international-phone/style.css';
import clsx from 'clsx';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { Typography } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
  modalOverlay: {
    padding: '24px',
    overflow: 'visible',
  },
  inputContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    width: '100%',
  },
  phoneInputContainer: {
    width: '100%',
    ['--react-international-phone-height']: '50px',
  },
  inputField: {
    width: '100%',
    border: '2px solid black !IMPORTANT',
    borderLeft: '1px solid black !IMPORTANT',
    ['--react-international-phone-border-radius']: '0px 10px 10px 0px',
    ['--react-international-phone-font-size']: '18px',
    boxShadow: 'none !IMPORTANT',
    '&:focus': {
      outline: '1px solid black !IMPORTANT',
      boxShadow: 'none !IMPORTANT',
    },
    '&:hover': {
      outline: '1px solid black !IMPORTANT',
      boxShadow: 'none !IMPORTANT',
    },
  },
  countriesContainer: {
    marginTop: '10px !IMPORTANT',
  },
  countrySelector: {
    border: '2px solid black !IMPORTANT',
    borderRight: '1px solid black !IMPORTANT',
    borderRadius: '10px 0px 0px 10px !IMPORTANT',
    paddingLeft: '4px !IMPORTANT',
    marginRight: '0px !IMPORTANT',
    '&:focus': {
      outline: '1px solid black !IMPORTANT',
      boxShadow: 'none !IMPORTANT',
    },
    '&:hover': {
      outline: '1px solid black !IMPORTANT',
      ['--react-international-phone-country-selector-background-color-hover']: '#fff',
      boxShadow: 'none !IMPORTANT',
    },
  },
  countryListItem: {
    '&:hover': {
      outline: '2px auto black !IMPORTANT',
      backgroundColor: '#D1D1D1 !IMPORTANT',
    },
  },
  helpLabel: {
    marginBottom: '28px',
  },
  errorMessage: {
    color: 'red',
    textAlign: 'center',
    marginBottom: '16px',
  },
}));

const ContactNumberProvider = ({
  cardAction: { displayText, value },
  sendMessageBackWithPdfCheck,
  ...buttonProperties
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [number, setNumber] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [confirmPressed, setConfirmPressed] = useState(false);
  const valueJson = JSON.parse(value);
  const modalLabels = JSON.parse(valueJson.ModalLabels);
  const classes = useStyles();

  const phoneUtil = PhoneNumberUtil.getInstance();

  const isPhoneValid = (phone) => {
    try {
      return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
    } catch (error) {
      return false;
    }
  };

  const onConfirm = (phoneNumber) => {
    setConfirmPressed(true);

    if (isValid) {
      sendMessageBackWithPdfCheck(phoneNumber, phoneNumber, phoneNumber);
    }
  };

  return (
    <>
      <ClaimButton
        handleClick={() => setIsOpen(true)}
        label={displayText}
        buttonProperties={buttonProperties}
      />
      <ModalOverlay className={classes.modalOverlay} open={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlayTitle style={{ marginBottom: '-28px' }}>{modalLabels.header}</ModalOverlayTitle>
        <ModalOverlayBody>
          {modalLabels.helpLabel && (
            <Typography className={classes.helpLabel}>{modalLabels.helpLabel}</Typography>
          )}
          <div className={classes.inputContainer}>
            <PhoneInput
              className={clsx(classes.phoneInputContainer)}
              inputClassName={clsx(classes.inputField)}
              defaultCountry={valueJson.DefaultCountryCode ?? 'gb'}
              countrySelectorStyleProps={{
                buttonClassName: clsx(classes.countrySelector),
                dropdownStyleProps: {
                  className: clsx(classes.countriesContainer),
                  listItemClassName: clsx(classes.countryListItem),
                },
              }}
              value={number}
              onChange={(phone) => {
                setNumber(phone);
                setIsValid(isPhoneValid(phone));
              }}
            />
          </div>
          {!isValid && confirmPressed && (
            <Typography className={classes.errorMessage}>{modalLabels.errorMessage}</Typography>
          )}
          <ClaimButton
            handleClick={() => onConfirm(number)}
            label={modalLabels.confirmButton}
            externalClassName='external-modal-button'
          />
        </ModalOverlayBody>
      </ModalOverlay>
    </>
  );
};

ContactNumberProvider.propTypes = {
  cardAction: PropTypes.object,
  sendMessageBackWithPdfCheck: PropTypes.func,
  buttonProperties: PropTypes.shape({
    color: PropTypes.string,
    size: PropTypes.string,
    variant: PropTypes.string,
    disabled: PropTypes.bool,
  }),
};

export default ContactNumberProvider;
