export const TenantIdList = {
  boom: '8f26a535-f634-489e-b37c-5a52d20e0bb4',
  ri: '69F7DFD6-8465-4505-69FB-08D8DC9F4203',
  vkn: '76ffad2b-b7ba-47fc-89f0-ba6d9faddd05',
  nnnl: '4B2D86D5-0317-4EED-81E1-B61B2383C5BE',
  theaa: 'b3190738-a6d4-41b0-9cfa-f947623d92df',
  wns: 'e253acc1-95de-44be-8525-b0e2d88cad96',
  aabs: '71c39a79-3434-40da-9747-5285169221f0',

  //Need to add all of them to lowercase as it come in clientConfigs
};
